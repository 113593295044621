import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://control.arabcast.tv';

const api = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  maxRedirects: 0, // Don't follow redirects automatically
  validateStatus: function (status) {
    return status >= 200 && status < 500; // Accept all responses between 200-499
  }
});

// Add request interceptor for error handling
api.interceptors.request.use(
  (config) => {
    // Handle FormData requests
    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    }
    
    // Add CSRF token if available
    const token = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
    if (token) {
      config.headers['X-CSRF-Token'] = token;
    }

    return config;
  },
  (error) => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

// Add response interceptor for error handling
api.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error.response) {
      // Server responded with error status
      if (error.response.status === 401) {
        // Handle unauthorized access
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

export const login = async (username, password) => {
  // Check for hardcoded credentials
  if (username === 'manager' && password === 'jtcLIZOWr09b') {
    return { success: true, data: { username } };
  }
  return { success: false, error: 'Invalid credentials' };
};

export const getWatching = async () => {
  const response = await api.get('/query.php?method=watching');
  return response.data;
};

export const getPayments = async () => {
  const response = await api.get('/query.php?method=payments');
  return response.data;
};

export const getMonthly = async () => {
  const response = await api.get('/query.php?method=monthly');
  return response.data;
};

export const searchUser = async (username, email) => {
  const response = await api.get('/query.php', {
    params: {
      method: 'search',
      username,
      email
    }
  });
  return response.data;
};

export const updateSubscription = async (username, days) => {
  const response = await api.get('/query.php', {
    params: {
      method: 'update',
      username,
      days
    }
  });
  return response.data;
};

export const updateAllowed = async (username, allowed) => {
  const response = await api.get('/query.php', {
    params: {
      method: 'allowed',
      username,
      allowed
    }
  });
  return response.data;
};

export const sendMessage = async (username, message) => {
  const formData = new FormData();
  formData.append('username', username);
  formData.append('method', 'message');
  formData.append('message', message);
  
  const response = await api.post('/send.php', formData);
  return response.data;
};

export const restartChannel = async (domain, channel) => {
  const formData = new FormData();
  formData.append('domain', domain);
  formData.append('channel', channel);
  
  const response = await api.post('/restart.php', formData);
  return response.data;
};

export const getActiveChannels = async () => {
  const response = await api.get('/query.php', {
    params: {
      method: 'channels'
    }
  });
  return response.data;
};

export const getSystemInsights = async () => {
  const response = await api.get('/query.php', {
    params: {
      method: 'insights'
    }
  });
  return response.data;
};