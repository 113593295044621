import React, { useState, useEffect } from 'react';
import { getActiveChannels, restartChannel } from '../services/api';
import LoadingState from './LoadingState';
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Alert,
  Snackbar
} from '@mui/material';
import {
  PlayArrow as PlayIcon,
  Refresh as RefreshIcon,
  Info as InfoIcon
} from '@mui/icons-material';

const Channels = () => {
  const [channels, setChannels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'info' });

  useEffect(() => {
    fetchChannels();
  }, []);

  const fetchChannels = async () => {
    try {
      const data = await getActiveChannels();
      setChannels(data);
    } catch (err) {
      setError('Failed to load channels');
    } finally {
      setLoading(false);
    }
  };

  const handleRestartChannel = async (channel) => {
    try {
      setNotification({ open: true, message: 'Restarting channel...', severity: 'info' });
      const url = new URL(channel.sources[0].url);
      await restartChannel(url.hostname, channel.title);
      setNotification({ open: true, message: 'Channel restarted successfully', severity: 'success' });
    } catch (err) {
      setNotification({ open: true, message: 'Failed to restart channel', severity: 'error' });
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedChannel(null);
  };

  if (loading) return <LoadingState />;

  if (error) {
    return (
      <Box p={3}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Grid container spacing={3}>
        {channels.map((channel, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardMedia
                component="img"
                height="140"
                image={channel['image-480x270']}
                alt={channel.title}
              />
              <CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                  <Typography variant="h6" noWrap>
                    {channel.title}
                  </Typography>
                  <Chip
                    label={channel.studio}
                    size="small"
                    color="primary"
                  />
                </Box>
                
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box>
                    <IconButton
                      onClick={() => handleRestartChannel(channel)}
                      color="primary"
                      title="Restart Channel"
                    >
                      <RefreshIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setSelectedChannel(channel);
                        setOpenDialog(true);
                      }}
                      color="info"
                      title="Channel Info"
                    >
                      <InfoIcon />
                    </IconButton>
                  </Box>
                  <IconButton
                    onClick={() => window.open(channel.sources[0].url, '_blank')}
                    color="secondary"
                    title="Play Stream"
                  >
                    <PlayIcon />
                  </IconButton>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Channel Info Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        {selectedChannel && (
          <>
            <DialogTitle>{selectedChannel.title}</DialogTitle>
            <DialogContent>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Studio:</strong> {selectedChannel.studio}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Duration:</strong> {selectedChannel.duration}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Available Formats:</strong>
              </Typography>
              {selectedChannel.sources.map((source, index) => (
                <Chip
                  key={index}
                  label={source.type.toUpperCase()}
                  sx={{ mr: 1, mb: 1 }}
                  size="small"
                />
              ))}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>Close</Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      {/* Notifications */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={() => setNotification({ ...notification, open: false })}
      >
        <Alert
          onClose={() => setNotification({ ...notification, open: false })}
          severity={notification.severity}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Channels;