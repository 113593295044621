import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AuthProvider, useAuth } from './utils/AuthContext';

import Layout from './components/Layout';
import Login from './components/Login';
import Watching from './components/Watching';
import Payments from './components/Payments';
import Search from './components/Search';
import Monthly from './components/Monthly';
import Message from './components/Message';
import Charts from './components/Charts';
import Channels from './components/Channels';
import Insights from './components/Insights';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
});

const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  if (!user) {
    return <Navigate to="/login" />;
  }
  return children;
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Layout />
                </ProtectedRoute>
              }
            >
              <Route index element={<Navigate to="/watching" />} />
              <Route path="watching" element={<Watching />} />
              <Route path="payments" element={<Payments />} />
              <Route path="monthly" element={<Monthly />} />
              <Route path="search" element={<Search />} />
              <Route path="message" element={<Message />} />
              <Route path="charts" element={<Charts />} />
              <Route path="channels" element={<Channels />} />
              <Route path="insights" element={<Insights />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;