import React, { useState, useEffect } from 'react';
import { getMonthly } from '../services/api';
import LoadingState from './LoadingState';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Chip
} from '@mui/material';

const Monthly = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMonthly = async () => {
      try {
        const data = await getMonthly();
        setSubscriptions(data);
      } catch (err) {
        setError('Failed to load monthly subscriptions');
      } finally {
        setLoading(false);
      }
    };

    fetchMonthly();
    const interval = setInterval(fetchMonthly, 60000); // Refresh every minute

    return () => clearInterval(interval);
  }, []);

  if (loading) return <LoadingState />;

  if (error) {
    return (
      <Box p={3}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  const getStatusColor = (expires) => {
    const expiryDate = new Date(expires);
    const now = new Date();
    const daysUntilExpiry = Math.floor((expiryDate - now) / (1000 * 60 * 60 * 24));

    if (daysUntilExpiry < 0) return 'error';
    if (daysUntilExpiry < 7) return 'warning';
    return 'success';
  };

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 800 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Username</TableCell>
            <TableCell>Subscription ID</TableCell>
            <TableCell>Expires</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subscriptions.map((sub, index) => (
            <TableRow key={index}>
              <TableCell>{sub.username}</TableCell>
              <TableCell>{sub.subscriptionID}</TableCell>
              <TableCell>{new Date(sub.expires).toLocaleDateString()}</TableCell>
              <TableCell>
                <Chip
                  label={getStatusColor(sub.expires) === 'error' ? 'Expired' : 'Active'}
                  color={getStatusColor(sub.expires)}
                  size="small"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Monthly;