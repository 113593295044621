import React, { useState } from 'react';
import { searchUser, updateSubscription, updateAllowed } from '../services/api';
import {
  Paper,
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Switch,
  FormControlLabel,
  Card,
  CardContent,
  Alert
} from '@mui/material';

const Search = () => {
  const [searchData, setSearchData] = useState({
    username: '',
    email: ''
  });
  
  const [userData, setUserData] = useState(null);
  const [days, setDays] = useState(30);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSearch = async () => {
    try {
      setError(null);
      const result = await searchUser(searchData.username, searchData.email);
      setUserData(result);
    } catch (err) {
      setError('Search failed. Please try again.');
    }
  };

  const handleUpdateSubscription = async (isAdd) => {
    try {
      setError(null);
      const daysToUpdate = isAdd ? days : -days;
      const data = await updateSubscription(userData.username, daysToUpdate);
      setUserData(prev => ({ ...prev, expiry: data.expiry }));
      setSuccess('Subscription updated successfully');
    } catch (err) {
      setError('Failed to update subscription');
    }
  };

  const handleToggleAllowed = async () => {
    try {
      setError(null);
      const newAllowed = userData.allowed === 0 ? 1 : 0;
      const data = await updateAllowed(userData.username, newAllowed);
      setUserData(prev => ({ ...prev, allowed: newAllowed }));
      setSuccess('Access status updated successfully');
    } catch (err) {
      setError('Failed to update access status');
    }
  };

  return (
    <Box p={3}>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <TextField
              fullWidth
              label="Username"
              value={searchData.username}
              onChange={(e) => setSearchData(prev => ({ ...prev, username: e.target.value }))}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              fullWidth
              label="Email"
              value={searchData.email}
              onChange={(e) => setSearchData(prev => ({ ...prev, email: e.target.value }))}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              fullWidth
              variant="contained"
              onClick={handleSearch}
              sx={{ height: '100%' }}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {success}
        </Alert>
      )}

      {userData && (
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>User Information</Typography>
                <Typography><strong>ID:</strong> {userData.id}</Typography>
                <Typography><strong>Username:</strong> {userData.username}</Typography>
                <Typography><strong>Email:</strong> {userData.email}</Typography>
                <Typography><strong>Mobile:</strong> {userData.mobile}</Typography>
                <Typography><strong>Subscription Expires:</strong> {userData.expiry}</Typography>
                
                <FormControlLabel
                  control={
                    <Switch
                      checked={userData.allowed === 1}
                      onChange={handleToggleAllowed}
                    />
                  }
                  label="Access Allowed"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>Subscription Management</Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Days"
                      value={days}
                      onChange={(e) => setDays(parseInt(e.target.value))}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => handleUpdateSubscription(true)}
                    >
                      Add
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="error"
                      onClick={() => handleUpdateSubscription(false)}
                    >
                      Remove
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export default Search;