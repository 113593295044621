import React, { useState, useEffect } from 'react';
import { getWatching } from '../services/api';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  CircularProgress
} from '@mui/material';

const Watching = () => {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const data = await getWatching();
        setSessions(data);
      } catch (err) {
        setError('Failed to load watching sessions');
      } finally {
        setLoading(false);
      }
    };

    fetchSessions();
    const interval = setInterval(fetchSessions, 30000); // Refresh every 30 seconds

    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={3}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 800 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>User</TableCell>
            <TableCell>TV Channel</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>Country</TableCell>
            <TableCell>City</TableCell>
            <TableCell>IP</TableCell>
            <TableCell>Agent</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sessions.map((session, index) => (
            <TableRow key={index}>
              <TableCell>{index + 1}</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>{session.username}</TableCell>
              <TableCell>{session.channel}</TableCell>
              <TableCell>{session.expires}</TableCell>
              <TableCell sx={{ color: 'success.main' }}>{session.country}</TableCell>
              <TableCell sx={{ color: 'success.main' }}>{session.city}</TableCell>
              <TableCell sx={{ color: 'warning.main' }}>{session.ipaddress}</TableCell>
              <TableCell sx={{ color: 'error.main' }}>{session.agent}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Watching;