import React, { useState, useEffect } from 'react';
import LoadingState from './LoadingState';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Card,
  CardContent,
  LinearProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Tooltip
} from '@mui/material';
import {
  TrendingUp,
  TrendingDown,
  Public,
  Warning,
  Refresh as RefreshIcon
} from '@mui/icons-material';

// Mock data - replace with actual API calls
const fetchInsights = async () => {
  // Simulate API call
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        activeUsers: 1250,
        totalStreams: 3500,
        averageWatchTime: 45,
        topCountries: [
          { country: 'United States', users: 450 },
          { country: 'United Kingdom', users: 320 },
          { country: 'Germany', users: 280 },
          { country: 'France', users: 200 },
          { country: 'Canada', users: 180 }
        ],
        systemHealth: {
          cpu: 65,
          memory: 78,
          storage: 45,
          bandwidth: 82
        },
        recentIssues: [
          { id: 1, type: 'Stream Error', message: 'Channel ABC stream interrupted', time: '10 minutes ago' },
          { id: 2, type: 'High Load', message: 'Server load peaked at 90%', time: '25 minutes ago' },
          { id: 3, type: 'Network', message: 'Network latency increased', time: '1 hour ago' }
        ]
      });
    }, 1000);
  });
};

const Insights = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const loadData = async () => {
    setLoading(true);
    try {
      const insights = await fetchInsights();
      setData(insights);
    } catch (err) {
      setError('Failed to load insights');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  if (loading) return <LoadingState />;

  if (error) {
    return (
      <Box p={3}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h5">System Insights</Typography>
        <Tooltip title="Refresh Data">
          <IconButton onClick={loadData}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Grid container spacing={3}>
        {/* Key Metrics */}
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Active Users
              </Typography>
              <Typography variant="h4">
                {data.activeUsers.toLocaleString()}
              </Typography>
              <Typography variant="body2" color="success.main" sx={{ display: 'flex', alignItems: 'center' }}>
                <TrendingUp sx={{ mr: 1 }} />
                +5% from last hour
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Total Streams
              </Typography>
              <Typography variant="h4">
                {data.totalStreams.toLocaleString()}
              </Typography>
              <Typography variant="body2" color="success.main" sx={{ display: 'flex', alignItems: 'center' }}>
                <TrendingUp sx={{ mr: 1 }} />
                +12% from yesterday
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Average Watch Time
              </Typography>
              <Typography variant="h4">
                {data.averageWatchTime} min
              </Typography>
              <Typography variant="body2" color="error.main" sx={{ display: 'flex', alignItems: 'center' }}>
                <TrendingDown sx={{ mr: 1 }} />
                -2% from last week
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Active Countries
              </Typography>
              <Typography variant="h4">
                {data.topCountries.length}
              </Typography>
              <Typography variant="body2" color="success.main" sx={{ display: 'flex', alignItems: 'center' }}>
                <TrendingUp sx={{ mr: 1 }} />
                +1 new country
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* System Health */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              System Health
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" gutterBottom>CPU Usage</Typography>
              <LinearProgress 
                variant="determinate" 
                value={data.systemHealth.cpu}
                color={data.systemHealth.cpu > 80 ? 'error' : 'primary'}
                sx={{ mb: 2 }}
              />
              
              <Typography variant="body2" gutterBottom>Memory Usage</Typography>
              <LinearProgress 
                variant="determinate" 
                value={data.systemHealth.memory}
                color={data.systemHealth.memory > 80 ? 'error' : 'primary'}
                sx={{ mb: 2 }}
              />
              
              <Typography variant="body2" gutterBottom>Storage Usage</Typography>
              <LinearProgress 
                variant="determinate" 
                value={data.systemHealth.storage}
                color={data.systemHealth.storage > 80 ? 'error' : 'primary'}
                sx={{ mb: 2 }}
              />
              
              <Typography variant="body2" gutterBottom>Bandwidth Usage</Typography>
              <LinearProgress 
                variant="determinate" 
                value={data.systemHealth.bandwidth}
                color={data.systemHealth.bandwidth > 80 ? 'error' : 'primary'}
              />
            </Box>
          </Paper>
        </Grid>

        {/* Recent Issues */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Recent Issues
            </Typography>
            <List>
              {data.recentIssues.map((issue) => (
                <React.Fragment key={issue.id}>
                  <ListItem>
                    <ListItemIcon>
                      <Warning color="error" />
                    </ListItemIcon>
                    <ListItemText
                      primary={issue.type}
                      secondary={
                        <>
                          {issue.message}
                          <Typography variant="caption" display="block" color="textSecondary">
                            {issue.time}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>

        {/* Geographic Distribution */}
        <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Geographic Distribution
            </Typography>
            <Grid container spacing={2}>
              {data.topCountries.map((country, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Box display="flex" alignItems="center" mb={2}>
                    <Public sx={{ mr: 2 }} />
                    <Box flexGrow={1}>
                      <Typography variant="body1">{country.country}</Typography>
                      <LinearProgress 
                        variant="determinate" 
                        value={(country.users / data.activeUsers) * 100}
                      />
                    </Box>
                    <Typography variant="body2" sx={{ ml: 2 }}>
                      {country.users} users
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Insights;